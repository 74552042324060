@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;700&display=swap");

body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.container {
  padding: 0 40px;
}

.modal-content {
  max-width: 1000px;
  margin: 0 auto;
}

.modal {
  display: none;
}

.form-section form {
  background: #fff;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.03);
  border-radius: 10px;
  padding: 30px 80px 70px 100px;
  position: relative;
}

.form-section form label {
  width: 100%;
  margin-bottom: 25px;
  position: relative;
}

.form-section form label input {
  font-size: 24px;
  line-height: 30px;
  color: #000;
  background: #f9f9f9;
  border-radius: 5px;
  border: 1px solid #f9f9f9;
  padding: 26px 26px 26px 69px;
  width: 100%;
  box-sizing: border-box;
  outline: 0;
}

.form-section form label input::placeholder {
  color: #777;
}

.form-section form label input::-webkit-input-placeholder {
  color: #777;
}

.form-section form label input::-moz-placeholder {
  color: #777;
}

.form-section form label input:-moz-placeholder {
  color: #777;
}

.form-section form label input:-ms-input-placeholder {
  color: #777;
}

.form-section form label svg {
  position: absolute;
  top: -5px;
  left: 30px;
}

.form-section form label input:focus {
  border: 1px solid #17bb6c;
  color: #17bb6c;
}

.form-section form label input:focus ~ svg path {
  fill: #17bb6c;
}

.form-section form input[type="submit"] {
  font-size: 24px;
  line-height: 30px;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 27px 20px 24px 20px;
  font-weight: 900;
  font-style: normal;
  background: linear-gradient(170.09deg, #6dd266 7.72%, #17bb6c 92.85%);
  box-shadow: 0 3px 0 #089b54;
  border-radius: 5px;
  width: 100%;
  border: 0;
  margin: 15px 0;
}

.form-section form input[type="submit"]:hover {
  background: linear-gradient(170.09deg, #72db6b 7.72%, #23cc7b 92.85%);
}

.form-section form input[type="submit"]:active {
  background: linear-gradient(170.09deg, #6dd266 7.72%, #17bb6c 92.85%);
}

@media only screen and (max-width: 700px) {
  .container {
    padding: 0 20px;
  }

  .app-header {
    justify-content: center;
  }

  .form-section form {
    padding: 0;
  }
}

:root {
  --primaryColor: #0cd130;
  --neutralLightColor: #eaeef2;
  --neutralDarkColor: #090a0a;
  --neutralSilverColor: #8490a1;
}

@font-face {
  font-family: 'Gilroy';
  src: url('/public/fonts/Gilroy-Regular.eot');
  src: url('/public/fonts/Gilroy-Regular.eot?#iefix') format('embedded-opentype'),
  url('/public/fonts/Gilroy-Regular.woff2') format('woff2'),
  url('/public/fonts/Gilroy-Regular.woff') format('woff'),
  url('/public/fonts/Gilroy-Regular.ttf') format('truetype'),
  url('/public/fonts/Gilroy-Regular.svg#Gilroy-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.app {
  text-align: center;
}
.app-header {
  font-family: "Gilroy";
  color: #5d5c5c !important;
  text-decoration: none;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-size: 18px;
  text-align: center;
  margin-top: 10px;
}
.app-logo {
  pointer-events: none;
  margin-right: 1rem;
  max-width: 100%;
}
.quiz {
  display: flex;
  height: 100%;
}
.quiz-left-container {
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 57%;
  height: 100vh;
}
.quiz-right-container {
  width: 43%;
  padding: 32px 20px 0;
  margin-left: auto;
}
.quiz-container {
  position: relative;
  min-height: 100%;
}
.quiz-left-container .quiz-image {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  max-width: 100%;
  max-height: 85%;
  transform: translate(-50%, -50%);
}
.quiz-indicator {
  max-width: 500px;
  padding: 20px 20px 16px;
  margin: 0 auto;
}
.quiz-indicator_bar,
.quiz-indicator_bar--Filled {
  position: relative;
  width: 100%;
  height: 4px;
  background: var(--neutralLightColor);
  border-radius: 2px;
}
.quiz-indicator_bar--Filled {
  position: absolute;
  background: var(--primaryColor);
  -webkit-transition: width 0.1s ease-in;
  transition: width 0.1s ease-in;
}
.quiz-progress {
  display: flex;
  max-width: 500px;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  margin: 0 auto;
}
.quiz-progress-numbers {
  color: var(--neutralDarkColor);
  font-size: 16px;
  font-weight: 600;
}
.quiz-progress-number--Selected {
  color: var(--primaryColor);
}
.quiz-content {
  max-width: 500px;
  height: 100%;
  padding: 20px 0;
  margin: 0 auto;
  color: var(--neutralDarkColor);
}
.quiz-question-title {
  z-index: 1;
  margin-bottom: 16px;
  color: var(--neutralDarkColor);
  font-size: 24px;
  font-weight: 700;
  line-height: 1.33;
  text-align: center;
}
.quiz-question-answers {
  display: inline-block;
  width: 100%;
}
.quiz-question-answer {
  z-index: 1;
  min-height: 72px;
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  margin: 16px 0;
  position: relative;
  border: 1px solid var(--neutralLightColor);
  border-radius: 12px;
  color: var(--neutralDarkColor);
  cursor: pointer;
  text-align: start;
}

.quiz-question-answer span {
  margin-right: 10px;
}

.quiz-question-answer--Selected {
  border: 1px solid var(--primaryColor);
  box-shadow: 0 12px 24px rgb(43 49 57 / 8%), 0 4px 8px rgb(43 55 70 / 6%);
}

.quiz-question-answer-content {
  flex-grow: 1;
  padding: 16px 0 16px 16px;
}

.quiz-question-answer-header {
  color: var(--neutralDarkColor);
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.quiz-question-answer-description {
  color: var(--neutralSilverColor);
  font-size: 14px;
  line-height: 20px;
}

.quiz-question-answer-checkbox {
  position: absolute;
  opacity: 0;
}
.quiz-question-answer-checkbox + label {
  position: relative;
  padding: 0;
  cursor: pointer;
}
.quiz-question-answer-checkbox:checked + label:before {
  width: 20px;
  height: 20px;
  border: none;
  background: var(--primaryColor);
}
.quiz-question-answer-checkbox + label:before {
  display: inline-block;
  border-radius: 12px;
  content: "";
  vertical-align: text-top;
}
.quiz-question-answer-checkbox:checked + label:after {
  position: absolute;
  top: 2.5px;
  left: 2.5px;
  width: 16px;
  height: 16px;
  background-image: url(https://res.cloudinary.com/drhg6wpcy/image/upload/q_auto:eco/public/images/checkmark-white.svg);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 9px 7px;
  border-radius: 20px;
  content: "";
}

.quiz-input-container {
  position: relative;
  display: flex;
  align-items: stretch;
  width: 100%;
}

.quiz-input {
  width: 100%;
  height: 45px;
  padding: 8px 21px;
  border: 2px solid var(--neutralLightColor);
  border-radius: 12px;
  font-size: 16px;
  outline: none;
}

.quiz-input-append {
  margin-left: -1px;
}

.quiz-input:active,
.quiz-input:focus,
.quiz-input:focus-visible,
.quiz-input:hover {
  border: 2px solid var(--primaryColor);
}

.quiz-button-container {
  margin: 32px 0 0;
}

.quiz-button {
  padding: 15px 24px;
  border: 0;
  border-radius: 12px;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 1px;
  line-height: 1.5;
  outline: 0;
  text-align: center;
  text-decoration: none;
  transition: all 0.2s;
  background: var(--primaryColor);
}

.quiz-results {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.quiz-progressbar {
  max-width: 220px;
  max-height: 220px;
}

iframe {
  max-width: 100vw;
}

@media only screen and (min-width: 1024px) {
  .quiz-question-answer-content {
    padding: 28px 0 28px 32px;
  }
}
@media only screen and (min-width: 1024px) {
  .quiz-content {
    padding: 20px 16px;
    padding: 20px;
  }
}
@media only screen and (min-width: 1024px) {
  .quiz-question-title {
    font-size: 32px;
    font-weight: 700;
    line-height: 1.25;
  }
}

@media only screen and (max-width: 700px) {
  .quiz-right-container {
    width: 100%;
  }

  .quiz-left-container {
    display: none;
  }

  iframe {
    width: 100vw;
  }
}

@media screen and (max-width: 1199px) {
  .app-logo {
      width: 150px;
      margin-right: 10px;
  }

  .app-header {
      font-size: 15px;
  }

  .app-header span {
      text-align: left;
  }
}
